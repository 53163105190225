<template>
  <table class="table is-bordered is-striped is-fullwidth">
    <thead>
      <tr>
        <th class="has-text-left">Option</th>
        <th class="has-text-left">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="o in Object.keys(selectedOptions)" :key="o">
        <template v-if="!codes.includes(o)">
          <td class="has-text-left">{{ o }}</td>
          <td class="has-text-left" v-if="!selectedOptions[o].toUpperCase().includes('NO ')">{{ selectedOptions[o] }}</td>
          <td class="has-text-left" v-else><font-awesome-icon icon="times" class="has-text-danger" /></td>
        </template>
        <template v-else>
          <td class="has-text-left">{{ selectedOptions[o] }}</td>
          <td class="has-text-left"><font-awesome-icon  icon="check" class="has-text-success" /></td>
        </template>
      </tr>
    </tbody>
  </table>
  <table v-if="selectedTruck" class="table is-bordered is-striped is-fullwidth">
    <thead>
      <tr>
        <th class="has-text-left">Truck Option</th>
        <th class="has-text-left">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="has-text-left">Size</td>
        <td class="has-text-left">{{ truckSize }}</td>
      </tr>
      <tr v-for="o in truckData" :key="o">
        <td class="has-text-left">{{ o.VALUE_EN }}</td>
        <td class="has-text-left">{{ selected[o.CODE] }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>

export default {
  props: {
    selected:{
      type: Object,
      default:undefined
    },
    selectedOptions:{
      type: Object,
      default:undefined
    },
    selectedTruck:{
      type: String,
      default:""
    },
    schema:{
      type: Object,
      default:undefined
    }
  },
  data() {
    return {
    }
  },
  computed: {
    truckSize() {
      return this.getTruckSize(this.selectedTruck)
    },
    truckData() {
      return this.schema.filter(x=>x.OPTION==this.selectedTruck)
    },
    codes(){
      return this.schema.map(x => x.CODE)
    }
  },
  methods: {
    getTruckSize(truck){
        return this.schema.find(x=>x.CODE==truck).VALUE_EN
    },
    getItemIcon(item){
      switch(item){
        case "Lid":
          return "box-archive"
        case "Pallet":
          return "pallet"
        case "Sleeve":
          return "box"
        case "Truck":
          return "truck"
      }
      return ""
    }
  }
}
</script>
<style lang="scss">
  @import "../assets/custom.scss";
  .table{
    thead{
      tr{
        th{
          color:white;
          background-color: $success;
        }
      }
    }
  }
  .table{
    font-size:0.8em;
  }
  @media print
  {
      tr{
        -webkit-print-color-adjust: exact;
      }
  }
</style>
