<template>
  <nav class="navbar is-fixed-top has-shadow">
    <div class="navbar-brand">
      <a @click="$emit('resetSelected')" class="navbar-item is-size-5 is-family-primary has-text-primary">
        <img src="/favicon.png" class="mr-2" /> CORPLEX AkyPak® Container Configurator
      </a>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="menuOpen=!menuOpen"
        :class="{'is-active':menuOpen}"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{'is-active':menuOpen}">
      <div class="navbar-start">
      </div>
      <div class="navbar-end" v-if="!selected">
        <div class="navbar-item is-block-fullhd">
          <button
            class="button is-small mr-1 is-family-secondary"
            :class="{'is-success':filters.family==f,'is-primary is-outlined':filters.family!=f}"
            @click="$emit('setFamily',f)"
            v-for="f in families"
            :key="'filter-family-'+f"
          >
            <span v-if="f==filters.family" class="icon">
              <font-awesome-icon icon="check" />
            </span>
            <span>{{ f.replace("AkyPak ","") }}</span>
          </button>
          <button class="button is-small is-primary" @click="$emit('setFamily','')">
            <span class="icon">
              <font-awesome-icon icon="times" />
            </span>
          </button>
        </div>
        <div class="navbar-item is-block-fullhd">
          <p class="control has-icons-left has-icons-right">
            <input class="input is-small" v-model="freetextValue" @input="$emit('setFreetext',freetextValue)" type="text" placeholder="Search">
            <span class="icon is-left">
              <font-awesome-icon icon="search" aria-hidden="true" />
            </span>
            <span class="icon is-right is-clickable has-text-primary" @click="freetextValue='';$emit('setFreetext',freetextValue)">
              <font-awesome-icon icon="times" aria-hidden="true" />
            </span>
          </p>
        </div>

        <div class="navbar-item">
          <span class="icon is-hidden-mobile"><font-awesome-icon icon="user" /></span>
          <span class="mr-1 has-text-weight-bold is-hidden-mobile">{{ username }}</span>
          <button class="button is-danger is-small" @click="$emit('logout')">
            <span class="icon"><font-awesome-icon icon="right-from-bracket" /></span>
            <span>Logout</span>
          </button>
        </div>
      </div>
      <div class="navbar-end" v-else>
        <div class="navbar-item">
          <button class="button is-primary is-small" @click="$emit('back')">
            <span class="icon"><font-awesome-icon icon="arrow-left" /></span>
            <span>Back</span>
          </button>
        </div>
        <div class="navbar-item">
          <button class="button is-primary is-small" @click="$emit('print')">
            <span class="icon"><font-awesome-icon icon="print" /></span>
            <span>Print</span>
          </button>
        </div>
        <div class="navbar-item">
          <span class="icon"><font-awesome-icon icon="user" /></span>
          <span class="mr-1 has-text-weight-bold">{{ username }}</span>
          <button class="button is-danger is-small" @click="$emit('logout')">
            <span class="icon"><font-awesome-icon icon="right-from-bracket" /></span>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    filters: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Object,
      default:undefined
    },
    families: {
      type: Array,
      default(){
        return []
      }
    },
    username:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      freetextValue:"",
      menuOpen:false
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  nav{
    padding-top:0;
    padding-bottom:0;
  }
</style>
