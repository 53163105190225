<template>
  <table class="table is-bordered is-striped is-fullwidth">
    <thead>
      <tr>
        <th class="has-text-left">Name</th>
        <th class="has-text-left">Value</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="s in schema" :key="s.CODE">
        <tr v-if="['INNER','OUTER','INFO'].includes(s.FILTER.toUpperCase())">
          <td class="has-text-left">{{s.VALUE_EN }}</td>
          <td v-if="!(['HID','HOD'].includes(s.CODE))" class="has-text-left">{{ selected[s.CODE] }}</td>
          <td v-if="filters.inner.height && s.CODE=='HID'" class="has-text-left">{{ filters.inner.height }}</td>
          <td v-if="filters.outer.height && s.CODE=='HOD'" class="has-text-left">{{ filters.outer.height }}</td>
          <td v-if="filters.inner.height && s.CODE=='HOD'" class="has-text-left">{{ filters.inner.height-selected['HID'] }}</td>
          <td v-if="filters.outer.height && s.CODE=='HID'" class="has-text-left">{{ filters.outer.height+selected['HID'] }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>

export default {
  props: {
    selected:{
      type: Object,
      default:undefined
    },
    schema:{
      type: Object,
      default:undefined
    },
    filters:{
      type: Object,
      default:undefined
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss">
  @import "../assets/custom.scss";
  .table{
    thead{
      tr{
        th{
          color:white;
          background-color: $success;
        }
      }
    }
  }
  .table{
    font-size:0.8em;
  }
  @media print
  {
      tr{
        -webkit-print-color-adjust: exact;
      }
  }
</style>
