<template>
  <div class="modal is-active" v-if="!isLoggedIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-light">AkyPak® Container Configurator</p>
      </header>
      <section class="modal-card-body has-text-left">

        <div class="field">
          <label class="label">Username</label>
          <div class="control has-icons-left">
            <input class="input" name="username" type="text" v-on:keyup.enter="authenticate" v-model="login.username">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="user" />
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Password</label>
          <div class="control has-icons-left">
            <input class="input" name="password" type="password" v-on:keyup.enter="authenticate" v-model="login.password">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="lock" />
            </span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="authenticate">Login</button>
      </footer>
    </div>
  </div>
  <bulmaNavbar
    @print="print"
    @setFreetext="(f) => filters.freetext=f"
    @resetSelected="resetSelection();resetFilters()"
    @setFamily="(f) => filters.family = f"
    @logout="logout"
    @back="resetSelection()"
    v-model="filters.freetext"
    :username="user.username"
    :selected="selected"
    :families="families"
    :filters="filters"
    v-if="isLoggedIn"
  />
  <div class="section has-background-primary-light" v-show="isLoaded && isLoggedIn">
    <div class="columns" v-show="!selected">
        <div class="column is-4 has-text-left">
          <optionFilters
            :options="options"
            :filters="filters"
            :schema="schema"
            @toggle="(o) => toggleOption(o)"
            @reset="resetOptionFilters"
            @resetAll="resetFilters"
          />
        </div>

      <div class="column">
        <dimensionFilters
          @inner="(i) => filters.inner=i"
          @outer="(o) => filters.outer=o"
        />
        <div class="columns" v-for="products in chunkedProducts" :key="products">
          <div class="column is-one-quarter" v-for="product in products" :key="product">
            <bulmaCard
              @click="select(product)"
              :title="product.FN.replace('AkyPak','AkyPak®')"
              :image="productImages[product.N]"
              cssClass="is-clickable"
            >
              <p class="is-size-7"><span class="icon"><font-awesome-icon icon="arrow-right-to-bracket" /></span><span>{{ product.LID }} x {{ product.WID }}</span><span v-if="filters.inner.height"> x {{ filters.inner.height }}</span><span v-if="filters.outer.height"> x {{ filters.outer.height+product.HID }}</span><br />
              <span class="icon"><font-awesome-icon icon="arrow-right-from-bracket" /></span><span>{{ product.LOD }} x {{ product.WOD }}</span><span v-if="filters.outer.height"> x {{ filters.outer.height }}</span><span v-if="filters.inner.height"> x {{ filters.inner.height-product.HID }}</span></p>
            </bulmaCard>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selected">
      <div class="container">
        <h1 class="title is-family-primary has-text-primary">{{ productFullName }}</h1>
        <productOptions
          :schema="schema"
          :selectedOptions="selectedOptions"
          :selectedTruck="selectedTruck"
          :selected="selected"
          @resetOption="(o) => resetSelectedOption(o)"
          @toggleOption="(p) => { selectedOptions[p.option]!=p.value?selectedOptions[p.option]=p.value:codes.includes(p.option)?delete selectedOptions[p.option]:selectedOptions[p.option]=selectedOptions[p.option]}"
          @selectTruck="(t) => selectedTruck=t"
        />
        <div class="columns mt-5">
          <div class="column is-one-half">
            <bulmaCard
               cssClass="mb-2"
               :image="productImages[selected.N]"
               :title="productName"
               :noResize="true"
            />
            <optionTable
              :selectedTruck="selectedTruck"
              :selectedOptions="selectedOptions"
              :selected="selected"
              :schema="schema"
            />
          </div>
          <div class="column is-one-half has-text-left">
            <productTable
              :selected="selected"
              :filters="filters"
              :schema="schema"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section has-background-primary-light" v-if="isLoaded && selected && isLoggedIn">
    <div class="container">
      <div class="columns" v-for="i in getExtraImages(selected.FAMILY,selected.N)" :key="i">
        <div class="column is-one-third" v-for="product in i" :key="product">
          <bulmaCard
            :image="product"
            :title="product.replace(/^.*[\\\/]/, '').replace(/\..*$/,'').replace(selected.N,'')"
            :checked="selectedOptionValues.includes(product.replace(/^.*[\\\/]/, '').replace(/\..*$/,'').replace(selected.N,''))"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
import chunk from "chunk";
import {Buffer} from "buffer"
import bulmaNavbar from "./../components/bulmaNavbar.vue"
import bulmaCard from "./../components/bulmaCard.vue"
import optionFilters from "./../components/optionFilters.vue"
import dimensionFilters from "./../components/dimensionFilters.vue"
import productOptions from "./../components/productOptions.vue"
import optionTable from "./../components/optionTable.vue"
import productTable from "./../components/productTable.vue"


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  name: "HomeView",
  components: {
    bulmaNavbar,
    bulmaCard,
    optionFilters,
    dimensionFilters,
    productOptions,
    optionTable,
    productTable
  },
  data() {
    return {
      user:undefined,
      login:{
        username:undefined,
        password:undefined
      },
      db: [],
      filteredDb: [],
      schema: [],
      filters: {
        freetext:"",
        family:"",
        truck:"",
        inner:{
          length:undefined,
          width:undefined,
          height:undefined
        },
        outer:{
          length:undefined,
          width:undefined,
          height:undefined
        },
        options: {},
      },
      selected:undefined,
      selectedOptions:{},
      selectedTruck:undefined,
      images: undefined,
      users: undefined,
      productImages: {},
      dbLoaded:false,
      imagesLoaded:false,
      schemaLoaded:false,
      usersLoaded:false
    };
  },
  computed: {
    families() {
      return this.products?.map(x => x.FAMILY).filter(onlyUnique)
    },
    options() {
      return this.schema?.filter(x => x.FILTER.toUpperCase()=="OPTION").map(x => x.OPTION).filter(onlyUnique)
    },
    optionGroups() {
      return this.schema?.filter(x => x.FILTER.toUpperCase()=="OPTION").map(x => (x["OPTION GROUP"])?x["OPTION GROUP"]:x.CODE).filter(onlyUnique)
    },
    isLoaded(){
      return this.dbLoaded && this.imagesLoaded && this.schemaLoaded && this.usersLoaded
    },
    isLoggedIn(){
      return this.user
    },
    optionKeys() {
      return Object.keys(this.filters.options)
    },
    products() {
      var ref=this
      return this.db?.filter((x) => {
        if(ref.filters.freetext && !x.N.toUpperCase().includes(ref.filters.freetext.toUpperCase()) && !x.FN.toUpperCase().includes(ref.filters.freetext.toUpperCase()))return false
        if(ref.filters.family && x.FAMILY!=ref.filters.family)return false
        if(ref.filters.inner.length && ((x.LID<ref.filters.inner.length) || ((x.LID-ref.filters.inner.length)>300)))return false
        if(ref.filters.inner.width && ((x.WID<ref.filters.inner.width) || ((x.WID-ref.filters.inner.width)>300)))return false
        if(ref.filters.outer.length && ((x.LOD<ref.filters.outer.length) || ((x.LOD-ref.filters.outer.length)>100)))return false
        if(ref.filters.outer.width && ((x.WOD<ref.filters.outer.width) || ((x.WOD-ref.filters.outer.width)>100)))return false
        for(var i = 0; i < ref.optionKeys.length; i++){
          if(!x[ref.optionKeys[i]])return false
        }
        return true
      })
    },
    chunkedProducts(){
      if(this.products){
        return chunk(this.products,4)
      }else{
        return []
      }
    },
    productFullName(){
      return `${this.selected.FN}`.replace('AkyPak','AkyPak®')
    },
    productName(){
      var ref=this
      var name=[this.selected.N]
      this.optionGroups.forEach((x) => {
        if(ref.selectedOptions[x]){
          name.push(ref.getSchemaByValue(ref.selectedOptions[x]).CODE)
        }
      })
      return name.join(" ")
    },
    codes(){
      return this.schema.map(x => x.CODE)
    },
    selectedOptionValues(){
      return Object.keys(this.selectedOptions).map((x) => this.selectedOptions[x])
    }
  },
  methods: {
    print () {
      // Pass the element id here
      window.print();
    },
    resetFilters(){
      this.filters={
        freetext:"",
        family:"",
        truck:""
      }
      this.resetSelection()
      this.resetDimensionFilters()
      this.resetOptionFilters()
    },
    resetDimensionFilters(){
      this.filters.inner={}
      this.filters.outer={}
    },
    resetSelection(){
      this.selected=undefined,
      this.selectedOptions={},
      this.selectedTruck=undefined
    },
    resetOptionFilters(){
      var ref=this
      this.filters.options = {}
    },
    select(product) {
      this.selected = product
      this.resetSelectedOptions();
    },
    resetSelectedOptions(){
      this.selectedOptions = {}
      this.schema.forEach(x => {
        if(this.selected[x.CODE]==2){
          this.selectedOptions[x["OPTION GROUP"]?x["OPTION GROUP"]:x.CODE]=x.VALUE_EN
        }
      })
    },
    resetSelectedOption(option){
      var o = this.getSchemaByOption(option)
      this.schema.forEach(x => {
        if(x.OPTION==option && this.selectedOptions[x["OPTION GROUP"]?x["OPTION GROUP"]:x.CODE]){
          delete this.selectedOptions[x["OPTION GROUP"]?x["OPTION GROUP"]:x.CODE]
        }
      })

      this.schema.forEach(x => {
        if(this.selected[x.CODE]==2 && x.OPTION==option){
          this.selectedOptions[x["OPTION GROUP"]?x["OPTION GROUP"]:x.CODE]=x.VALUE_EN
        }
      })
    },
    getSchemaByValue(value){
      return this.schema.find(x => x.VALUE_EN==value)
    },
    getSchemaByOption(option){
      return this.schema.find(x => x.OPTION==option)
    },
    toggleOption(value) {
      var ref=this
      if(this.filters.options[value]){
        delete this.filters.options[value]
      }else{
        this.filters.options[value] = 1
      }
    },
    getImage(family,product){
      var image = undefined
      var productimage = undefined

      image = this.images[family].images.find(x => x == `${family}.jpg` || x == `${family}.png`)
      if(this.images[family] && this.images[family][product])
      productimage = this.images[family][product].images.find(x => x == `${product}.jpg` || x == `${product}.png`)

      if(!productimage)
        return `assets/images/${family}/` + image
      else {
        return `assets/images/${family}/${product}/` + productimage
      }
    },
    getExtraImages(family,product){
      var familyimages = []
      var productimages = []
      var images = []

      familyimages = this.images[family].images.filter(x => x != `${family}.jpg` && x != `${family}.png`)
      if(this.images[family] && this.images[family][product])
      productimages = this.images[family][product].images.filter(x => x != `${product}.jpg` && x != `${product}.png`)

      images = productimages.map(x =>{
        return `assets/images/${family}/${product}/` + x
      }).concat(familyimages.map(x =>{
        return `assets/images/${family}/` + x
      }))
      return chunk(images,3)
    },
    loadDb() {
      axios
        .get("assets/db.json")
        .then((res) => {
          this.db = res.data.db;
          this.dbLoaded=true
          this.loadSchema()
        })
        .catch(err => console.log(err));
    },
    loadSchema() {
      axios
        .get("assets/schema.json")
        .then((res) => {
          this.schema = res.data.schema;
          this.schemaLoaded=true;
          this.loadImages()
        })
        .catch(err => console.log(err));
    },
    loadImages() {
      var ref=this
      axios
        .get("assets/images.json")
        .then((res) => {
          this.images = res.data.families;
          this.db.forEach(x => {
            ref.productImages[x.N]=ref.getImage(x.FAMILY,x.N)
          })
          this.imagesLoaded=true;
          this.loadUsers()
        })
        .catch(err => console.log(err));
    },
    triggerApi(username){
      var ref=this
      axios
        .get("assets/api.json")
        .then((res) => {
          var api = res.data.api;
          axios
            .post(api,{"username":username,"timestamp":new Date().toISOString()})
            .then((res) => {
              console.log("Api triggered")
            }) 
            .catch(err => console.log(err));         
        })
        .catch(err => console.log(err));
    },
    loadUsers() {
      var ref=this
      axios
        .get("assets/users.json")
        .then((res) => {
          ref.users = []
          res.data.users.forEach((user)=>{
            var username = new Buffer(user.username,'base64').toString('utf8')
            var password = new Buffer(user.password,'base64').toString('utf8')
            ref.users.push({
              username: username,
              password: password
            })
          })
          this.usersLoaded=true;
          this.loadUser();
        })
        .catch(err => console.log(err));
    },
    getName(code,language="EN"){
      var ref=this
      return this.schema.find(x => x.CODE.toUpperCase()==code.toUpperCase)["VALUE_"+language]
    },
    loadUser(){
      console.log("Loading user from local storage")
      var ref=this
      var b64,decoded,arr,u
      try{
        b64 = localStorage.getItem('corplex_configurator_user')
        var buff = new Buffer(b64,'base64')
        if(b64){
          decoded = buff.toString('utf8')
          if(decoded){
            arr=decoded.split("::")
            if(arr.length==2){
              u={}
              u.username=arr[0]
              u.password=arr[1]
              console.log("User valid")
              this.users.forEach((user)=>{
                if(!ref.user){
                  if(user.username.toLowerCase()==u.username.toLowerCase() && user.password==u.password ){
                    ref.user=u
                    console.log("Authenticated")
                    ref.triggerApi(user.username)
                  }else{
                    ref.user=undefined
                    console.log("Bad username or password")
                  }
                }

              })
            }else{
              console.log("Bad credentials")
            }
          }
        }
      }catch(e){
        console.log(e)
        localStorage.removeItem('corplex_configurator_user')
        this.user=undefined
      }

    },
    authenticate(){
      console.log("Authenticating")
      var buff = new Buffer(this.login.username+"::"+this.login.password)
      localStorage.setItem('corplex_configurator_user',buff.toString('base64'))
      this.loadUser()
    },
    logout(){
      localStorage.removeItem('corplex_configurator_user')
      this.loadUser()
      this.login={}
    }

  },
  mounted() {
    this.loadDb();
    this.resetFilters();
    this.resetSelection();
    document.title= "Corplex AkyPak® Container Configurator"
  }
};
</script>
<style lang="scss">

  .is-pagebreak-before{
    page-break-before: always
  }

  @media print
  {
      div.columns{
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        -webkit-break-inside: avoid;
      }
      div.column{
        margin-top:10mm
      }
  }
</style>
