<template>
  <div class="mb-2 box no-print has-text-left">
    <h2 class="subtitle is-family-secondary has-text-left has-text-success is-capitalized">Choose your options</h2>
    <div class="columns" v-for="o in options" :key="o">
      <div class="column is-2 is-family-secondary is-size-5 has-text-primary has-text-weight-bold">
        {{ o }}
      </div>
      <div class="column">
        <template v-for="og in optionGroups" :key="og">
          <template v-for="s in schema.filter(x => (x.CODE==og ||x['OPTION GROUP']==og) && x.OPTION==o)" :key="s.CODE">
            <button
              @click="$emit('toggleOption',{option:og,value:s.VALUE_EN})"
              class="is-small button is-capitalized is-family-secondary m-1"
              :class="{'is-success':selectedOptions[og]==s.VALUE_EN,'is-primary is-outlined':selectedOptions[og]!=s.VALUE_EN}"
              v-if="selected[s.CODE]"
            >
              <span v-if="selectedOptions[og]==s.VALUE_EN" class="icon"><font-awesome-icon icon="check" /></span>
              <span>{{ s.VALUE_EN }}</span>
            </button>
          </template>
        </template>
        <button class="button is-small is-primary m-1" @click="$emit('resetOption',o)"><font-awesome-icon icon="times" /></button>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2 is-family-secondary is-size-5 has-text-primary has-text-weight-bold">
        Truck Size
      </div>
      <div class="column">
        <button
          v-for="t in trucks"
          :key="t"
          @click="$emit('selectTruck',t)"

          class="is-small button is-family-secondary m-1"
          :class="{'is-success':selectedTruck==t,'is-primary is-outlined':selectedTruck!=t}"
        >
          <span v-if="selectedTruck==t" class="icon"><font-awesome-icon icon="check" /></span>
          <span>{{ getTruckSize(t) }}</span>
        </button>
        <button class="button is-small is-primary m-1" @click="$emit('selectTruck',undefined)"><font-awesome-icon icon="times" /></button>
      </div>
    </div>
  </div>
</template>
<script>

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  props: {
    schema: {
      type: Object,
      default:undefined
    },
    selected: {
      type: Object,
      default:undefined
    },
    selectedOptions:{
      type: Object,
      default:undefined
    },
    selectedTruck:{
      type: String,
      default:""
    }
  },
  data() {
    return {
    }
  },
  computed: {
    options() {
      return this.schema?.filter(x => x.FILTER.toUpperCase()=="OPTION").map(x => x.OPTION).filter(onlyUnique)
    },
    optionGroups() {
      return this.schema?.filter(x => x.FILTER.toUpperCase()=="OPTION").map(x => (x["OPTION GROUP"])?x["OPTION GROUP"]:x.CODE).filter(onlyUnique)
    },
    trucks() {
      return this.schema?.filter(x => x.FILTER.toUpperCase()=="TRUCK").map(x => x.OPTION).filter(onlyUnique)
    }
  },
  methods: {
    getTruckSize(truck){
        return this.schema.find(x=>x.CODE==truck)?.VALUE_EN
    }
  }
}
</script>
<style type="scss">
  @media print
  {
      .no-print, .no-print *
      {
          display: none !important;
      }
  }
</style>
