<template>
  <div class="box">
    <div class="block" v-for="o in options" :key="o">
      <h2 class="subtitle is-family-primary has-text-primary">
        {{ o }} Filter
      </h2>
      <button
        class="button is-small m-1 is-family-secondary"
        :class="{'is-primary':filters.options[ov.CODE],'is-success is-outlined':!filters.options[ov.CODE]}"
        @click="$emit('toggle',ov.CODE)"
        v-for="ov in optionValues(o)"
        :key="'filter-option-'+ov.CODE"
      >
        <span v-if="filters.options[ov.CODE]" class="icon">
          <font-awesome-icon icon="check" />
        </span>
        <span class="is-capitalized">{{ ov.VALUE_EN }}</span>
      </button>
    </div>
    <button class="p-1 mb-1 button is-outlined is-primary is-fullwidth" @click="$emit('reset')">
      <span class="icon">
        <font-awesome-icon icon="times" />
      </span>
      <span class="is-uppercase">Reset Options</span>
    </button>
    <button class="p-1 button is-primary is-fullwidth" @click="$emit('resetAll')">
      <span class="icon">
        <font-awesome-icon icon="times" />
      </span>
      <span class="is-uppercase">Reset all Filters</span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Object,
      default:undefined
    },
    options: {
      type: Array,
      default(){
        return []
      }
    },
    schema: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
    }
  },
  methods: {
    optionValues(option) {
      return this.schema.filter(x => x.OPTION==option)
    },
  }
}
</script>
<style lang="scss">

</style>
