<template>
  <div class="box">
    <h2 class="subtitle is-family-primary has-text-primary has-text-left mb-1">Filter on Dimensions</h2>
    <div class="level mb-3 pb-0">
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="inner.length" @input="$emit('inner',inner)" type="number" placeholder="inner length">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="left-right" />
        </span>
      </div>
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="inner.width" @input="$emit('inner',inner)" type="number" placeholder="inner width">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="up-right-and-down-left-from-center" />
        </span>
      </div>
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="inner.height" @input="outer.height=undefined;$emit('inner',inner)" type="number" placeholder="inner height">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="up-down" />
        </span>
      </div>
      <button class="button is-small is-primary mr-1" @click="resetInner();$emit('inner',inner)">
        <span class="icon">
          <font-awesome-icon icon="times" />
        </span>
      </button>
    </div>
    <div class="level mb-3 pb-0">
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="outer.length" @input="$emit('outer',outer)" type="number" placeholder="outer length">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="left-right" />
        </span>
      </div>
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="outer.width" @input="$emit('outer',outer)" type="number" placeholder="outer width">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="up-right-and-down-left-from-center" />
        </span>
      </div>
      <div class="control level-item has-icons-left mr-1">
        <input class="input is-small" v-model="outer.height" @input="inner.height=undefined;$emit('outer',outer)" type="number" placeholder="outer height">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="up-down" />
        </span>
      </div>
        <button class="button is-small is-primary" @click="resetOuter();$emit('outer',outer)">
          <span class="icon">
            <font-awesome-icon icon="times" />
          </span>
        </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
      inner:{
        length:undefined,
        width:undefined,
        height:undefined
      },
      outer:{
        length:undefined,
        width:undefined,
        height:undefined
      }
    }
  },
  methods: {
    resetInner(){
      this.inner={
        length:undefined,
        width:undefined,
        height:undefined
      }
    },
    resetOuter(){
      this.outer={
        length:undefined,
        width:undefined,
        height:undefined
      }
    }
  }
}
</script>
<style lang="scss">
</style>
